/*
 * Sidebar
*/
.sidebar {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
  @include transition(max-width 0.3s);

  @include media-breakpoint-up(md) {
    width: 100%;
    max-width: 260px;
  }

  .nav {
    white-space: nowrap;
  }

  .nav-item {
    border: $border-width solid transparent;
    &.active {
      & > .nav-link {
        color: $light;
        background-color: #2e3650;
        border: $border-width solid lighten($dark, 20%);
      }
    }
    .nav-link {
      color: $light;
      border: $border-width solid $dark;
      &:hover {
        color: $light;
        background-color: #2e3650;
      }
      &.active {
        color: $white;
      }
    }
    margin-bottom: 0.2rem;
  }

  .nav-link + .multi-level {
    margin-top: 0.2rem;
  }

  .nav-link {
    font-size: $font-size-base;
    vertical-align: middle;
    padding: 0.55rem 0.75rem;
    @include border-radius($border-radius);

    .sidebar-icon {
      margin-right: 0.5rem;
      color: $white;
      span {
        min-width: 22px;
      }
      svg {
        min-width: 22px;
      }
      &.svg-icon {
        margin-right: -40px;
      }
    }

    .link-arrow {
      font-size: $font-size-sm;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.1rem rgba(#4c5680, 0.2);
    }
  }
  .nav-link.active {
    color: $primary;
  }

  .multi-level {
    .nav-link {
      padding-left: 45px;
    }
  }

  .sidebar-text,
  .link-arrow,
  .badge,
  .notification-count {
    opacity: 1;
    @include transition(opacity 0.3s);
  }

  @include media-breakpoint-up(md) {
    .sidebar-text-contracted {
      display: none;
      @include transition(opacity 0.3s);
    }

    &.contracted {
      .sidebar-text,
      .link-arrow,
      .badge {
        opacity: 0;
      }

      .notification-count {
        opacity: 1;
        position: absolute;
        top: 0;
        left: 35px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        font-size: 0.7rem;
        padding: 0.2rem;
      }

      .nav-item {
        position: relative;
        width: 47px;
        &.active {
          & > .nav-link {
            background-color: $primary;
          }
        }
        .nav-link {
          &:hover {
            background-color: $primary;
          }
        }
      }

      .sidebar-text-contracted {
        display: inline;
      }

      .multi-level .nav-link {
        padding-left: 17px;
      }

      .nav-item {
        white-space: nowrap;
      }

      .sidebar-icon {
        text-align: center;
      }

      @include media-breakpoint-up(md) {
        & + .content {
          margin-left: 95px;
        }
      }

      max-width: 95px;
    }
  }
}

.react-datepicker__input-container input[type="text"] {
  display: block;
  width: 100%;
  padding: 0.55rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #66799e;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0.0625rem solid #d1d7e0;
  appearance: none;
  border-radius: 0.5rem;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust the height as needed */
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.loader-element {
  animation: spin 2s linear infinite; /* Modify animation duration and type as needed */
}

.quation-select {
  width: 50% !important;
  height: 100%;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rs-picker.rs-picker-cascader.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-cleanable.rs-picker-countable {
  width: 60% !important;
}

.expanding-content {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease-in-out;
  }
  
  .expanded {
	max-height: 100%; /* Adjust this value to the maximum height you want */
	margin-bottom: 2rem;
  }

  
.sidebar-inner {
  position: relative;
  overflow-y: hidden;
  .accordion-button {
    &::after {
      background-image: none;
      content: "\f054";
      font-family: $font-awesome-5;
      font-size: $font-size-sm;
      font-weight: 900;
      height: 1.25rem;
      width: 0.5rem;
    }
    &:not(.collapsed) {
      background-color: #2e3650;
      &::after {
        transform: rotate(90deg);
        transition: all 0.2s ease;
      }
    }
  }

  .accordion {
    padding-top: 0.25rem;
  }

  .accordion-body {
    padding: 0.5rem 0 0 0;
  }

  .accordion-collapse {
    border: 0;
  }
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.user-card {
  border-bottom: 0.0625rem solid #2e3650;
}

@include media-breakpoint-down(sm) {
  .sidebar {
    width: 100%;
  }
}

.content {
  overflow: visible;
  padding: 0 $spacer 0 $spacer;
  @include transition(all 0.3s);

  @include media-breakpoint-up(md) {
    margin-left: 260px;
  }
}

.sidebar-toggle {
  font-size: 1.3rem;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
    background: $gray-400;
  }
}

.sidebar-transition-enter {
  opacity: 0;
}

.sidebar-transition-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.sidebar-transition-exit {
  opacity: 1;
}

.sidebar-transition-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

a:hover, a:focus {
  text-decoration: none !important;
}
