/**
 * = Navigation bars
 */

.navbar-main {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;

  .navbar-toggler-icon {
    background-image: $navbar-dark-toggler-icon-bg;
  }
}

.navbar .navbar-nav {
  .nav-link {
    @include transitions(0.8s, $transition-base);

    @include media-breakpoint-down(md) {
      font-size: 1.2rem;
      @include display-flex();
      @include justify-content(space-between);
      @include align-items(center);
    }
  }

  .nav-item {
    .media:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    &:focus,
    .nav-link:focus {
      outline: none !important;
    }
  }

  .dropdown {
    .dropdown-menu {
      top: 100%;
      span {
        width: 30px;
      }
    }

    .dropdown-item {
      font-size: 0.875rem;
    }
  }

  .dropdown-megamenu,
  .dropdown-megamenu-sm,
  .dropdown-megamenu-md {
    @include media-breakpoint-up(lg) {
      min-width: 580px;
    }

    .megamenu-link {
      color: $gray;
      font-weight: 400;
      font-size: $font-size-sm;
    }

    .megamenu-item {
      position: relative;

      &:hover {
        .megamenu-link {
          color: $primary;
        }
      }
    }
  }

  .dropdown-megamenu-sm {
    @include media-breakpoint-up(lg) {
      min-width: 320px;
    }
  }

  .dropdown-megamenu-md {
    @include media-breakpoint-up(lg) {
      min-width: 960px;
    }
  }

  @include media-breakpoint-up(lg) {
    .dropdown-menu {
      .dropdown-toggle .nav-link-arrow {
        @include transform(rotate(-90deg));
      }
    }

    .dropdown.show > .dropdown-toggle .nav-link-arrow {
      @include transform(rotate(180deg));
    }

    .dropdown-toggle:hover .nav-link-arrow {
      @include transform(rotate(0deg));
    }
  }

  .dropdown-submenu {
    position: relative;

    .dropdown-menu {
      top: 0;
      // left: calc(100% - 2px);
      margin-left: 0.1rem;
      margin-right: 0.1rem;
      opacity: 0;
      display: none;

      &.show {
        opacity: 1;
        pointer-events: all;
        display: block;
      }
    }
  }

  .nav-link-arrow {
    transition: $transition-transform;
  }
}

.navbar-brand {
  .brand-text {
    font-size: 1.2rem;
    font-weight: $font-weight-bold;
    color: $secondary;
  }

  img {
    height: 27px;
  }
}

// Navbar text and logo dark & light
.navbar-dark {
  .navbar-brand-dark {
    display: none;
  }

  .navbar-brand {
    color: $white;
  }

  & > .nav-item:hover > .nav-link {
    color: $primary;
  }
}

.navbar-light {
  &:not(.headroom--not-top) {
    .btn-outline-soft {
      border-color: $dark;
      color: $dark;

      &:hover {
        background: $dark;
        color: $white;
      }
    }
  }

  .navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-bg;
  }

  .navbar-brand-light {
    display: none;
  }

  .navbar-brand {
    color: $gray-800;
  }
}

img.sidebar-icon.svg-icon {
  width: 100%;
}

/* QuizPreview.css */

.table-row {
  font-size: 20px;
  background: #f5f8fb;
  border-bottom: 1px solid grey;
}
.quiz-container {
  max-width: 600px;
  margin: 0 auto;
}

/* Styles for the select element when hovered */
.blue {
  background: #17a5ce;
    height: 15px;
    width: 16px;
    border-radius: 50%;
    margin-top: 6px;
    margin-right: 14px;
}

.black {
  background: black;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin-top: 4px;
    margin-right: 14px;
}

.green {
  background: #1B998B;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-top: 4px;
  margin-right: 14px;
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  /* color: #fff; */
  width: 100%;
  height: 40px;
  outline: none;
}

.question-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  margin-left: 12px;
}

.question-header {
  text-align: center;
  margin-bottom: 10px;
}

.question-content {
  margin-bottom: 15px;
}

.question-text {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.question-image {
  display: block;
  max-width: 100%;
  margin-bottom: 10px;
  border-radius: 6px;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.option-button {
  padding: 10px 20px;
  margin: 5px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
}

.explanation {
  font-size: 14px;
  color: #666;
}

button.page-button {
  border: 0;
  border-radius: 1.875rem !important;
  transition: all 0.3s;
  margin: 0 3px;
  min-width: 1.875rem;
  height: 1.875rem;
  font-weight: 400;
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  background: #999;
  padding: 0.1em 0.5em;
  color: white;
}

button.page-button.current-page {
  background: #4caf50;
}

.pagination {
  margin-top: 25px;
}

.dataTables_length.bs-select {
  margin-bottom: 25px;
}

.common-bg {
  background: #f2f4f8;
  border-radius: 30px;
  padding: 20px 20px 100px 20px;
}
.form-group {
  position: relative;
}
label.custom-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #333f4e;
  position: absolute;
  top: 35px;
  z-index: 22;
  left: 15px;
}
input.custom-input {
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(66, 71, 97, 0.1);
  border-radius: 12px;
  border: none;
  padding: 38px 16px 16px 16px;
  width: 100%;
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #a3b2c7;
  outline: none;
}

.btn-6 {
  color: #112a46;
  padding: 18px 32px;
  font-size: 18px;
  background-color: #b8daff;
}

a.blue-thin {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  background: #7288fa;
  border-radius: 41px;
  padding: 9px 35px;
  color: #ffffff;
  display: inline-block;
}

.pop-up-head-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

a.fill-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  padding: 16px 55px;
  background: #7288fa;
  box-shadow: 0px 8px 30px rgba(65, 89, 214, 0.3);
  border-radius: 41px;
  color: #ffffff;
  display: inline-block;
  margin-right: 15px;
  border: 1px solid transparent;
}

/* styles.css (or your preferred stylesheet) */
.switch-container {
  display: flex;
  align-items: center;
}

.switch-label {
  margin-right: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: background-color 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Additional styles for the switch label (optional) */
.switch-label {
  font-size: 14px;
}

.question-card.col-md-6 {
  width: 45% !important;
}

.card {
  position: relative;
  width: 100%;
  overflow-x: scroll;
}

.download-btn {
  color: #ffffff;
  background-color: #262b40;
  border-color: #262b40;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

/* Add this CSS to your stylesheets or style tag */

.ans-text {
  border-radius: 5px;
  border: 2px solid #8a959f;
  padding: 5px 5px;
  min-width: 80%;
}

.correct-option {
  background: #429c38;
  color: white;
  border-radius: 5px;
  border: 2px solid #8a959f;
  padding: 5px 5px;
  min-width: 80%;
}

.float-rightIcon {
  float: right;
}
// Theme color variants
// eg. navbar-theme-primary, navbar-theme-secondary
@each $color, $value in $theme-colors {
  .navbar-theme-#{$color}:not(.headroom) {
    background-color: $value;
  }

  .navbar-theme-#{$color}.navbar-light:not(.headroom) .navbar-nav {
    .dropdown-item,
    .list-group-item {
      &.active,
      &:hover {
        color: $primary;
      }
    }

    @include media-breakpoint-down(md) {
      .nav-link,
      .show > .nav-link,
      .dropdown-item,
      .list-group-item {
        &,
        &:focus,
        &.active,
        &:hover {
          color: $primary;
        }
      }
    }
  }

  .navbar-theme-#{$color} .navbar-nav {
    .dropdown-item,
    .list-group-item {
      &.active,
      &:hover {
        color: $primary;
      }
    }

    @include media-breakpoint-down(md) {
      .nav-link,
      .show > .nav-link,
      .dropdown-item,
      .list-group-item {
        &,
        &:focus,
        &.active,
        &:hover {
          color: $value;
          background: transparent;
        }

        &.disabled {
          color: $navbar-light-disabled-color;
        }
      }

      .dropdown .dropdown-menu {
        padding: 0;
      }

      .dropdown-item {
        padding-right: 0;
      }

      .dropdown:not(.mega-dropdown) {
        .dropdown-item {
          padding-left: 0;
        }
      }
    }
  }
}

.dropdown-menu {
  top: 100%;
  span {
    width: 30px;
  }
}

.navbar-transparent {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

@include media-breakpoint-up(lg) {
  .navbar-nav {
    .nav-item {
      [data-toggle="dropdown"]::after {
        transition: $transition-base;
      }

      &.show {
        [data-toggle="dropdown"]::after {
          @include transform(rotate(180deg));
        }
      }
    }
    .nav-link {
      i {
        margin-right: 0.3rem;
        font-size: $font-size-xs;
      }
    }

    .nav-link-icon {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-size: 1rem;

      i {
        margin-right: 0;
      }
    }

    .dropdown-menu {
      opacity: 0;
      pointer-events: none;
      margin: 0;
      border-radius: $dropdown-border-radius;
    }

    .dropdown-menu-right {
      &:before {
        right: 20px;
        left: auto;
      }
    }

    .dropdown-menu-center {
      left: 50%;
      transform: translateX(-50%);
    }

    .dropdown-menu,
    .dropdown-megamenu,
    .dropdown-megamenu-md {
      &.show {
        opacity: 1;
        pointer-events: auto;
      }

      &.close {
        display: block;
      }
    }

    .dropdown-submenu .dropdown-menu {
      transform: none;
    }

    .dropdown-menu {
      position: relative;
      display: block;
      opacity: 0;
      pointer-events: none;
      transition: $transition-base;
    }

    .dropdown.show,
    .dropdown-submenu.show {
      & > .dropdown-menu {
        display: block;
        opacity: 1;
        pointer-events: auto;
      }
    }

    .dropdown-menu-inner {
      position: relative;
      padding: 1rem;
    }
  }

  .navbar-transparent {
    .navbar-nav {
      .nav-link {
        &.disabled {
          color: $navbar-dark-disabled-color;
        }
      }
    }

    .navbar-brand {
      color: $navbar-dark-color;
    }
  }
}

// Collapse
.navbar-collapse-header {
  display: none;
}

.navbar-collapse {
  width: auto;
}

@include media-breakpoint-down(lg) {
  .navbar-nav {
    .nav-link {
      padding: 0.625rem 0;
    }

    .dropdown-menu {
      box-shadow: none;
      width: 100%;

      .media svg {
        width: 30px;
      }
    }
  }

  .dropdown-menu-center {
    right: 0;
    left: auto;
    transform: translate(0, 0);
  }

  .navbar-collapse {
    // width: calc(100% - 30px);
    position: absolute;
    top: 0;
    right: 15px;
    z-index: 1050;
    overflow-y: auto;
    // using !important to overwrite Bootstrap's inline styles
    // height: calc(100vh - 30px) !important;
    opacity: 0;

    .navbar-toggler {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      padding: 0;

      span {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        opacity: 1;
        background: #283448;
      }
    }

    .navbar-collapse-header {
      display: block;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }

    .collapse-brand img {
      height: 35px;
    }

    .collapse-close {
      text-align: right;
    }
  }

  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    padding: 1.5rem;
    border-radius: $dropdown-border-radius;
    background: $white;
    animation: show-navbar-collapse 0.2s ease forwards;
    @include box-shadow(0 0 20px rgba(0, 0, 0, 0.1));
  }

  .navbar-collapse.collapsing-out {
    animation: hide-navbar-collapse 0.2s ease forwards;
  }
}

.navbar-dashboard {
  border-radius: $border-radius;
}

.icon-notifications {
  .unread-notifications {
    position: absolute;
    right: -5px;
    top: -5px;
  }
}
